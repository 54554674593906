<script lang="ts" setup>
import definitions from '@respell/steps';
import { useVueFlow } from '@vue-flow/core';

const emit = defineEmits(['confirm']);
const modal = useModal();
const canvasStore = useCanvasStore();

const { errors, elements } = storeToRefs(canvasStore);
const { addSelectedNodes } = useVueFlow({
  id: 'editor',
});

function onConfirm() {
  emit('confirm');
}
function close() {
  modal.close();
}

const getStep = (slug: string) => {
  return elements.value?.find((el) => el.data.slug === slug);
};

const getDefinition = (slug: string) => {
  return definitions[getStep(slug)?.data.key];
};

function handleIssue(slug: string) {
  const step = getStep(slug);
  addSelectedNodes([step]);
  close();
}

defineShortcuts({
  enter: {
    handler: () => {
      onConfirm();
    },
  },
});
</script>

<template>
  <UModal>
    <UCard
      :ui="{
        base: 'w-full max-h-[80vh]',
        body: {
          base: 'flex flex-col w-full gap-2',
        },
        header: {
          base: 'flex w-full justify-between sticky top-0 bg-white border-b border-gray-200 z-50',
        },
      }"
      class="h-full w-full overflow-y-scroll"
    >
      <template #header>
        <p class="title">Publish with issues?</p>
        <UButton
          :padded="false"
          color="gray"
          variant="link"
          icon="i-ph-x"
          @click="close"
        />
      </template>
      <div v-for="(formErrors, slug) in errors" :key="slug" class="contents">
        <UAlert
          v-if="formErrors.length"
          :icon="getDefinition(slug).icon"
          :title="getStep(slug).label ?? getDefinition(slug).name"
          class="w-full cursor-pointer"
          :ui="{
            title: 'font-bold text-lg text-left',
            color: {
              white: {
                solid: 'hover:bg-gray-50',
              },
            },
          }"
          @click="() => handleIssue(slug)"
        >
          <template #icon="{ icon }">
            <UIcon
              :name="icon"
              class="rounded-full border-gray-50 outline outline-gray-200 border bg-white text-2xl"
            />
          </template>
          <template #description>
            <ul class="list-disc space-y-2 text-red-500">
              <li
                v-for="(error, index) in formErrors"
                :key="index"
                class="text-left"
              >
                {{
                  getDefinition(slug)?.options[error.path]?.name ?? error.path
                }}:
                {{ error.message }}
              </li>
            </ul>
          </template>
        </UAlert>
      </div>

      <template #footer>
        <div class="flex flex-row gap-2 justify-between w-full">
          <UButton
            label="Cancel"
            variant="solid"
            color="white"
            size="xl"
            block
            class="shrink"
            @click="close"
          />
          <UButton
            label="Publish"
            size="xl"
            variant="solid"
            color="red"
            block
            class="shrink"
            @click="onConfirm"
          />
        </div>
      </template>
    </UCard>
  </UModal>
</template>
