<script setup lang="ts">
import { useSpellsStore } from '~/stores/spells';

const spellStore = useSpellsStore();

const { spell } = storeToRefs(spellStore);

const tabs = computed(() => [
  {
    label: 'Run',
    icon: 'i-ph-play-circle-bold',
    replace: true,
    to: { name: 'spell.run', params: { spellId: spell.value?.id } },
  },
  {
    label: 'Bulk',
    icon: 'i-ph-fast-forward-bold',
    replace: true,
    to: { name: 'spell.bulk', params: { spellId: spell.value?.id } },
  },
  {
    label: 'Schedule',
    icon: 'i-ph-calendar-dots',
    replace: true,
    to: { name: 'spell.schedule', params: { spellId: spell.value?.id } },
  },
  {
    label: 'History',
    icon: 'i-ph-clock-counter-clockwise-bold',
    replace: true,
    to: { name: 'spell.history', params: { spellId: spell.value?.id } },
  },
  {
    label: 'API',
    icon: 'i-ph-code-bold',
    replace: true,
    to: { name: 'spell.api', params: { spellId: spell.value?.id } },
  },
  {
    label: 'Slack Bot',
    icon: 'i-ph-slack-logo-bold',
    replace: true,
    to: { name: 'spell.slack', params: { spellId: spell.value?.id } },
  },
]);
</script>

<template>
  <UHorizontalNavigation
    :links="tabs"
    :ui="{
      wrapper: 'w-fit',
      container: 'bg-gray-100 rounded-lg px-1 gap-1',
      active: 'before:bg-white',
      base: 'py-2 px-4',
      before: 'before:inset-y-1',
      after: 'after:h-0',
    }"
  >
    <template #default="{ link }">
      <span class="hidden xl:block relative truncate">{{ link.label }}</span>
    </template>
  </UHorizontalNavigation>
</template>
