<script setup lang="ts">
import { formatDistanceToNow } from 'date-fns';
import AppDropdown from '~/components/app/AppDropdown.vue';
import AppSpellIcon from '~/components/app/AppSpellIcon.vue';
import { categories } from '~/util/categories';

const modal = useModal();
const spellStore = useSpellsStore();
const workspaceStore = useWorkspaceStore();
const userStore = useUserStore();

const { spell, template, versions, liveGraph } = storeToRefs(spellStore);
const { workspaceId } = storeToRefs(workspaceStore);
const { user } = storeToRefs(userStore);

const { data } = await useApi('/api/graphRuns', {
  query: {
    spellId: spell.value?.id as string | undefined,
    workspaceId,
  },
});

const state = reactiveComputed(() => {
  return {
    previewId: template.value?.preview?.id ?? undefined,
    tags: spell.value?.tags ?? [],
    isOfficial: template.value?.isOfficial ?? false,
  };
});

const runOptions = computed(() => {
  return data.value?.graphRuns
    ?.filter(
      (run) => run.state === 'success' && run.graphId === liveGraph.value?.id,
    )
    .sort((a, b) => b.endedAt - a.endedAt);
});

const onSubmit = async (event: FormSubmitEvent) => {
  const { data: metadata } = event;
  const updates = { ...metadata };
  await spellStore.updateSpell(spell.value?.id, updates);
  modal.close();
  await navigateTo({ name: 'preview', params: { spellId: spell.value?.id } });
};
</script>
<template>
  <UModal>
    <UForm :state="state" class="contents" @submit="onSubmit">
      <UCard
        :ui="{ base: 'w-full', footer: { base: 'flex justify-between gap-2' } }"
      >
        <template #header>
          <span class="flex justify-between">
            <p class="title">
              {{ template ? 'Update template' : 'Make a template' }}
            </p>
            <UButton
              :padded="false"
              color="gray"
              variant="link"
              icon="i-ph-x"
              @click="modal.close"
            />
          </span>
        </template>
        <div class="flex flex-col w-full gap-2 items-start">
          <AppSpellIcon :spell="spell" />
          <UFormGroup size="xl" :label="spell?.name" />
          <UFormGroup
            size="md"
            name="preview"
            label="Select a preview run"
            class="w-full"
          >
            <USelectMenu
              v-model="state.previewId"
              :options="runOptions"
              value-attribute="id"
              option-attribute="id"
              placeholder="Select preview"
            >
              <template #option="{ option: run, selected }">
                <UBadge
                  v-if="run.isTestRun"
                  label="Test"
                  color="red"
                  size="xs"
                />
                <UBadge size="xs">
                  {{ versions?.find((v) => v.id === run.graphId)?.version }}
                </UBadge>
                <p
                  class="caption text-black"
                  :class="{ 'text-gray-50': selected }"
                >
                  {{ run.id }}
                </p>
                <p class="caption" :class="{ 'text-gray-300': selected }">
                  Completed {{ formatDistanceToNow(run.createdAt) }} ago
                </p>
              </template>
            </USelectMenu>
          </UFormGroup>
          <UFormGroup size="md" name="tags" label="Tags" class="w-full">
            <AppDropdown
              v-model="state.tags"
              :options="Object.values(categories)"
              multiple
              searchable
              placeholder="Select tags"
              searchable-placeholder="Search for tags..."
            />
          </UFormGroup>
          <!-- TODO: Replace with a CASL check -->
          <UFormGroup
            v-if="user?.isAdmin"
            label="Respell Official Template"
            class="w-full mt-m"
          >
            <template #hint>
              <UToggle v-model="state.isOfficial" color="primary" />
            </template>
          </UFormGroup>
        </div>
        <template #footer>
          <UButton
            label="Cancel"
            variant="solid"
            color="white"
            size="md"
            block
            class="shrink"
            @click="modal.close"
          />
          <UButton
            label="Save"
            size="md"
            variant="solid"
            color="primary"
            block
            class="shrink"
            type="submit"
          />
        </template>
      </UCard>
    </UForm>
  </UModal>
</template>
