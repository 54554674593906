<script setup lang="ts">
import type { DropdownItem } from '#ui/types';
import ShareModal from '~/components/modals/ShareModal.vue';
import TemplateModal from '~/components/modals/TemplateModal.vue';

const userStore = useUserStore();
const spellStore = useSpellsStore();
const modal = useModal();

const { user } = storeToRefs(userStore);
const { template, spell } = storeToRefs(spellStore);

const shareOptions = computed((): DropdownItem[][] => {
  const baseOptions: DropdownItem[][] = [
    [
      {
        label: 'Invite Teams',
        icon: 'i-ph-user-plus',
        click: () => modal.open(ShareModal),
      },
    ],
  ];

  // TODO: For some reason, CASL is failing here
  // if (can('read', subject('Spell', spell))) {
  if (user.value?.id === spell.value?.creatorId && !template.value) {
    baseOptions.push([
      {
        label: 'Make a template',
        icon: 'i-ph-browsers',
        // Assuming `action` is a valid property for DropdownItem after extending its type
        click: () => modal.open(TemplateModal),
      },
    ]);
  }

  return baseOptions;
});
</script>
<template>
  <UDropdown :items="shareOptions">
    <UButton
      icon="i-ph-share-fat-bold"
      size="xl"
      color="white"
      variant="solid"
      label="Share"
      :trailing="false"
    />
  </UDropdown>
</template>
