<script setup lang="ts">
import GuestTopbar from '~/components/home/GuestTopbar.vue';
import SpellTopbar from '~/components/spell/SpellTopbar.vue';

const spellStore = useSpellsStore();
const { spell, trigger } = storeToRefs(spellStore);
const { routeName } = useRouteName();
const { status } = useAuth();
const spellId = useRouteParams('spellId');

const workspaceStore = useWorkspaceStore();
const { personalTeam } = storeToRefs(workspaceStore);

const { error: spellError, pending } = await useAsyncData(() =>
  spellStore.loadCompleteSpell(spellId.value as string),
);

await useAsyncData(() => spellStore.loadVersions(spellId.value as string), {
  lazy: true,
});

onBeforeMount(async () => {
  if (
    [
      'spell.bulk',
      'spell.run',
      'spell.slack',
      'spell.schedule',
      'spell.api',
      'spell',
    ].includes(routeName.value as string) &&
    trigger.value
  ) {
    await navigateTo({
      name: 'spell.history',
      params: { spellId: spell.value?.id },
      replace: true,
    });
  } else if (routeName.value === 'spell') {
    await navigateTo({
      name: 'spell.run',
      params: { spellId: spell.value?.id },
      replace: true,
    });
  }
});

watchImmediate(spellError, async () => {
  if (spellError.value) {
    throw new Error(spellError.value);
  }
});
</script>
<template>
  <div class="grow flex flex-col justify-start max-h-screen overflow-hidden">
    <SpellTopbar v-if="status === 'authenticated'" />
    <GuestTopbar v-else />
    <NuxtPage
      v-if="spell"
      :keepalive="routeName !== 'editor'"
      :page-key="spellId as string"
    />
    <div
      v-else-if="pending"
      key="loading"
      class="flex justify-center items-center w-full h-60"
    >
      <AppLoadingSpinner />
    </div>
    <div v-else class="flex flex-col justify-center items-center h-60">
      <UIcon name="i-ph-smiley-melting" class="text-gray-400 text-3xl" />
      <p class="h4 mt-ls mb-xs">No spell found</p>
      <p class="body dimmed flex is-wrap mb-s">
        This spell doesn't exist or you don't have access to it.
      </p>
      <UButton
        icon="i-ph-magic-wand"
        type="button"
        size="xl"
        @click="navigateTo(`/teams/${personalTeam?.id}/spells`)"
      >
        My Spells
      </UButton>
    </div>
  </div>
</template>
