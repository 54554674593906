<script setup lang="ts">
import { subject } from '@casl/ability';
import type { Graph } from '@respell/database';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';

const props = defineProps<{
  spellId: string;
}>();
const spellStore = useSpellsStore();
const canvasStore = useCanvasStore();

const { can } = useAppAbility();

const modal = useModal();

const { restoreGraph } = canvasStore;

const { data: versions, refresh } = await useAsyncCache(
  `graphs/${props.spellId}`,
  async () => spellStore.loadVersions(props.spellId as string),
  { watch: [props], lazy: true },
);

const versionIcons = {
  draft: 'i-ph-pencil-simple',
  live: 'i-ph-rocket',
  generated: 'i-respell-spellcaster',
};

const openRestoreModal = (graphId: string) => {
  modal.open(ConfirmModal, {
    action: 'restore',
    type: 'version',
    message:
      'Restoring this version will overwrite the current draft. Are you sure you want to continue?',
    isDangerous: true,
    async onConfirm() {
      await restoreGraph(graphId);
      await refresh();
    },
  });
};

const handleVersionClick = (graph: Graph) => {
  return async () => {
    if (graph.type !== 'draft') {
      openRestoreModal(graph.id);
    }
  };
};

const getVersionLabel = (graph: Graph) => {
  switch (graph.type) {
    case 'generated':
      return 'Generated';
    case 'draft':
      return 'Draft';
    default:
      return graph.version;
  }
};

const versionOptions = computed(() => {
  return versions.value?.length
    ? versions.value
        ?.filter(
          (graph: Graph) =>
            graph.version !== 'v0' || graph.type === 'generated',
        )
        ?.map((graph: Graph) => {
          return [
            {
              label: getVersionLabel(graph),
              icon:
                versionIcons[graph.type as keyof typeof versionIcons] ||
                'i-ph-clock-counter-clockwise',
              class:
                graph.type === 'draft' ? 'bg-gray-200 pointer-events-none' : '',
              click: handleVersionClick(graph),
              disabled: !can('restore', subject('Graph', graph)),
            },
          ];
        })
    : [];
});
</script>
<template>
  <UDropdown
    v-if="versionOptions.length"
    :items="versionOptions"
    :popper="{ placement: 'bottom-start' }"
  >
    <div class="flex flex-row items-center space-x-2 is-clickable">
      <UIcon
        name="i-ph-clock-counter-clockwise"
        class="text-3xl text-gray-600"
      />
      <p class="body dimmed">Version</p>
      <UIcon name="i-ph-caret-down-bold" class="text-xl text-gray-600" />
    </div>
  </UDropdown>
</template>
